<template>
  <div class="onboarding__container">
    <h1 class="smalltitle" style="padding-bottom: 100px; padding-top: 20px">
      {{ current_user.username }}, we are setting up Carl-bot in
      {{ current_guild.name }}
    </h1>
    <div class="underlined__title">
      <h4 class="smalltitle">MODERATION</h4>
    </div>
    <div>
      <b-card class="onboarding__card" style="margin-bottom: 1px">
        <b-form-group>
          <h4 class="teenytinytitle">Mute Role (Highly Recommended)</h4>
          <p>
            This is the role used for all the mute commands as well as automated
            mutes from automod (more on that later). If you are not 100% certain
            how discord permissions work, it is recommended to let the bot set
            up the role for you.
          </p>
          <multiselect
            v-model="muterole"
            id="mute_role_select"
            track-by="id"
            label="name"
            placeholder="Please select a role"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="roleOptions"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color: #72767d" icon="user-tag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  class="option__title"
                  :style="{ color: '#' + intToHex(props.option.color) }"
                  >{{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </b-card>
      <b-card class="onboarding__card" style="margin-bottom: 1px">
        <b-form-group>
          <h4 class="teenytinytitle">
            Which channel should modlogs be posted in?
          </h4>
          <p>
            This is the channel where actions made by your moderators are
            posted. Useful for transparency and organization.
          </p>

          <multiselect
            v-model="modlog"
            id="mute_role_select"
            track-by="id"
            label="name"
            placeholder="Please select a channel"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="textChannelOptions"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color: #72767d" icon="hashtag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  class="option__title"
                  :style="{ color: '#' + intToHex(props.option.color) }"
                  >{{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </b-card>
    </div>
    <div class="onboarding__footer">
      <button class="saucy-btn" v-on:click="doUpdate">Next</button>
      <button class="saucy-btn-alt" v-on:click="goBack">Previous step</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import { createTags, VueTagsInput } from "@johmun/vue-tags-input";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {
    Callout,
    cSwitch,
    VueTagsInput,
    Multiselect,
  },
  data: function () {
    return {
      muterole: null,
      modlog: null,
    };
  },
  notifications: {
    showChannelWarningMessage: {
      type: VueNotifications.types.error,
      title: "Missing field",
      message: "You need to select a channel when using that mode.",
    },
  },
  computed: {
    current_user() {
      return this.$store.state.discord_user || {};
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        (x) => x.id == this.$route.params.guild_id
      );
    },
    roleOptions() {
      return [
        { name: "Create one for me", id: "-1" },
        ...this.$store.state.oauthState.roles,
      ];
    },
    textChannelOptions() {
      return [
        { name: "Create one for me", id: "-1" },
        ...this.$store.state.oauthState.channels,
      ];
    },
    groupedChannels() {
      const channels = this.$store.state.oauthState.channels || [];
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized",
          },
          channels: [],
        },
      ];
      let currentGroup = groups[0];
      for (let chn of channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find((g) => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
  },
  methods: {
    async doUpdate() {
      const guild_id = this.$route.params.guild_id;

      await this.axios.put(
        `/api/v1/servers/${guild_id}/onboarding/moderation`,
        {
          muterole: this.muterole ? this.muterole.id : null,
          modlog: this.modlog ? this.modlog.id : null,
        }
      );
      this.$router.push(`/onboarding/${guild_id}/automod`);
    },
    goBack() {
      const guild_id = this.$route.params.guild_id;

      this.$router.push(`/onboarding/${guild_id}/reactionroles`);
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },

    fetchConfig() {
      this.$Progress.start();
      this.$store.commit("onboardingStage", 3);
      this.axios
        .get(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/onboarding/moderation"
        )
        .then((r) => {
          const channels = this.$store.state.oauthState.channels;
          const roles = this.$store.state.oauthState.roles;
          this.muterole = roles.find((ro) => ro.id === r.data.muterole) || null;
          this.modlog = channels.find((c) => c.id === r.data.modlog) || null;

          this.$Progress.finish();
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.fetchConfig();
  },
  created: function () {
    this.fetchConfig();
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>
<style scoped>
.prefix__container {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
}
.prefix__example {
  background-color: #292a33;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: 500;
  border-radius: 3px;
}
</style>
<style>
.vue-tags-input {
  background: #505b64;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

div.vue-tags-input {
  background: #505b64 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  border: 1px solid #222823;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #22222a;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #292a33;
  color: #eee;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #292a33;
  color: #292a33;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}

.onboarding__container {
  display: flex;
  align-items: center;
  background-color: #22222a;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.underlined__title {
  border-bottom: 4px solid #49d6df;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-self: center;
}
</style>